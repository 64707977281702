.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 90%;
  margin: 0 auto;
}

.stats-box h3 {
  padding-top: 20px;
}

.box {
  background-color: black;
  height: 70px;
  border-radius: 8px;
}

.stats-box {
  background-color: black;
  border-radius: 8px;
  padding:0;
  margin: 0;
}

.skill-info {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 3%;
}


.skill-icon {
  background-color: #5b5b5b;
  border-radius: 2px;
  height: 20px;
  padding: 3px;
  width: 20px;
}

.skill-name {
  width: 10%;
}

.progress-bar {
  appearance: auto;
  box-sizing: border-box;
  width: 74%;
}

.level-value{
  width: 5%
}

.bossing-box {
  background-color: black;
  border-radius: 8px;
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between;
}

.boss-info {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 43%;
  padding: 0% 2.5% 0% 2.5%; 
}

.boss-info img {
  max-width: 100%; 
  padding: 0% 1% 0% 1%;
}

.boss-info p {
  padding: 0% 1% 0% 1%;
}

.bossing-box h3 {
  padding-top: 20px;
  width: 100%; 
}


.activities-box {
  background-color: black;
  border-radius: 8px;
  margin-top: 2%;
  display: flex;
  flex-wrap: wrap; 
}

.activities-info {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 43%;
  padding: 0% 2.5% 0% 2.5%; 
}

.activities-info img {
  max-width: 100%;
  padding: 0% 1% 0% 1%;
}

.activities-box h3 {
  padding-top: 20px;
  width: 100%;
}

.icon {
  font-size: 24px;
  margin-bottom: 10px;
  height: 30px
}

.box p {
  margin: 0;
  font-size: 16px;
}

@media (min-width: 768px) {
  .container {
    grid-template-columns: repeat(4, 1fr);
  }
}



@media (min-width: 768px) and (max-width: 1400px) {
  .progress-bar {
    width: 50%; 
  }
  .skill-name {
    font-size: 12px;
    width: 15%; 
  }
  .level-value{
    font-size: 12px;
  }
}

@media (max-width: 767px) {
  .progress-bar {
    width: 40%; 
  }
  .skill-name {
    width: 15%;
    font-size: 12px;  
  }
  .level-value{
    font-size: 12px;
  }
}