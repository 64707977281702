.site-header {
  background-color: #1c1c1c;
  color: #fff;
  padding: 1rem;
}

.site-title {
  margin: 0;
  font-size: 1.5rem;
  text-decoration: none;
  color: #FFD900;
  cursor: pointer;
}

  .header-button {
    border:none;
    color: #FFD900;
    background-image: black;
    text-decoration: none; 
}