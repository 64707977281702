#root {
  height: 102%;
}

.fullscreen-image {
  height:100%;
}


body {
  margin: 0;
  padding: 0;
  background-image: url('../../files/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  height: 100%;
  min-height: 100vh;
  overflow-x: hidden;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 100vh;
}