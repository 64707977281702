body {
margin: 0;
}

.content {
padding-top: 20px;
}

.loading-icon {
width:40px;
margin: auto;
}

.form-button {
    background-color: #FFD900; 
    border-color: #FFD900;
    color: black;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease; 
}

.form-button:hover {
    background-color: orange; 
}