.top-10-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  gap: 3%;
}

.top-10-item {
  background-color: black; 
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); 
  display: flex;
  flex-direction: column; 
  align-items: center; 
}

.top-10-image {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px; 
  background-size: cover;
}

.top-10-image-content {
  display: none;
  position: absolute;
  top: 100%; 
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  font-size: 1rem;
  text-align: center;
  padding: 10px;
  white-space: nowrap;
  border-radius: 5px;
}


.item-details-container {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: black;
  padding:0 3%;
  margin: 1%;
}

.top-10-image:hover .top-10-image-content {
  display: block;
}

.item-details-container img {
    padding-right: 2%;
}

.searched-item {
  width: 50px;
  height: 50px;
  padding-right: 2%;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
}

.image-overlay {
  display: none;
  position: absolute;
  top: 100%; 
  left: 50%;
  transform: translateX(-50%); 
  background-color: black;
  color: white; 
  font-size: 1rem;
  text-align: center;
  padding: 10px;
  white-space: nowrap; 
  max-width: 200px; 
}

.searched-item:hover .image-overlay {
  display: block;
  z-index: 9999;
}


.item-info {
    width: 100%; 
    display: flex;
    flex-direction: row; 
    justify-content: space-evenly; 
    align-items: center; 
}

.item-info h2 {
    font-size: 1rem; 
}

.item-info p {
    padding: 2%;
    flex: 1; 
}

.page-container {
  margin-top: 0vh;
}