.app-container {
    display: flex;
    background-color: #4d4d4d00;
  }
  
  .sidebar {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    background: #333;
    padding: 1rem;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
    min-height: 95vh;
    flex-shrink: 0;
  }
  
  .sidebar nav ul li a.active {
    color: #ffd900;
  }
  .sidebar.open {
    transform: translateX(0);
  }
  
  .content {
    flex-grow: 1;
    padding: 1rem;
  }
  
  .sidebar-toggle {
    position: fixed;
    background-color: #FFD900;
    top: 15px;
    right: 15px;
    z-index: 1001;
    height: 30px;
    width:30px;
  }
  
  .sidebar-toggle:hover {
    transition: background-color 0.5s ease;
    background-color: #ff8c00;
  }

  @media (min-width: 768px) {
    .sidebar {
      position: static;
      transform: translateX(0);
    }
  
    .sidebar-toggle {
      display: none;
    }
  
    .content {
      margin-left: 00px;
    }
  }
 
.sidebar nav ul li {
  margin-bottom: 2rem;
}

.sidebar nav ul li a {
  text-decoration: none;
  color: #fff;
  display: block;
}

.sidebar nav ul li a:hover{
  text-decoration: none;
  color: #ffd900;
  display: block;
}
.sidebar nav ul {
  list-style-type: none;
  padding-left: 0;
  font-weight: 600;
  padding-top:1vh;
  padding-left: 1vw;
}

body {
  margin: 0
}
