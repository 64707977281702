.content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top:-20px;
    min-height: 70vh;
  }
  
  .centered-content {
    max-width: 600px;
    padding: 1rem;
  }
  
  @media (min-width: 768px) {
    .content {
      min-height: calc(70vh);
    }
  }
  .input-group {
    display: flex;
    align-items: center;
  }
  
  .input-group .form-control {
    flex-grow: 1;
    margin-right: 0.5rem;
  }
  
  .input-group-append {
    flex-shrink: 0;
  }
