.content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
}

.centered-content {
  max-width: 600px;
  padding: 1rem;
}

@media (min-width: 768px) {
  .content {
    min-height: 75vh;
  }
}
.input-group {
  display: flex;
  align-items: center;
}

.input-group .form-control {
  flex-grow: 1;
  margin-right: 0.5rem;
}

.input-group-append {
  flex-shrink: 0;
}

.heading-yellow {
  color: #FFD900;
  line-height: 1;
  font-size: 2.75rem;
  transition: font-size 0.3s ease;

}

.form-container {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 8px;
  max-width: 200px;
  margin: auto;
}

.input-group {
  margin-bottom: 1rem;
}

.form-control {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-size: 0.8rem;
}

.btn-primary {
  background-color: #FFD900;
  border-color: #FFD900;
  color: black;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.8rem;
  transition: background-color 0.2s ease;
}

.btn-primary:hover {
  background-color: #ff8c00;
}

.content {
  line-height:1;
}