.content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 5vh;
    align-items: flex-start; 
  }
  
  .centered-content {
    max-width: 1200px;
    padding: 1rem;
  }
  
  @media (min-width: 768px) {
    .content {
      min-height: 5vh;
    }
  }
  .input-group {
    display: flex;
    align-items: center;
  }
  
  .input-group .form-control {
    flex-grow: 1;
    margin-right: 0.5rem;
  }
  
  .input-group-append {
    flex-shrink: 0;
  }

  .page-container {
    width:100%
  }


  @media (min-width: 768px) {
    .page-container {
      width:80%;
      
    }
  }

  .tracker-form, .tracker-display {
    margin: 0;
}