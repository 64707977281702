html, body {
    margin: 0;
    padding: 0;
    background-image: url('../../files/background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
}


.overlay {
    background-color: rgba(0, 0, 0, 0.5);
    min-height: 100%;
}


.form-button {
    background-color: #FFD900; 
    border-color: #FFD900;
    color: black;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.2s ease; 
}

.form-button:hover {
    background-color: orange; 
}