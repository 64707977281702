
  .content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;

    border-radius: 10px;
  }

  
 
  .content form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: center;
  }
  
  .content form input,
  .content form button {
    padding: 10px;
    margin-left:10px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .content form button {
    border-color: #FFD900;
    background-color: #FFD900;

    cursor: pointer;
  }
  
 
  .content p {
    text-align: center;
    
    color: #fff;
  }
  
  .content p button {
    background: none;
    border: none;
    color: #82b1ff;
    cursor: pointer;
    padding: 0;
  }